import {useRouter} from 'next/router';
import {Button, Heading, Stack} from '@halp/ui';
import {useImperativeQuery, useMutation} from '@halp/api/graphql';
import type {LevelOfStudy} from '@halp/util';
import {STUDY_LEVELS, URL_REGEX} from '@halp/util';
import {DocumentTypeSelector} from '../../Documents';
import {Paths} from '../../Paths';
import {
	FormAsyncSelect,
	FormButton,
	FormCurrencyField,
	FormField,
	FormSelect,
	GraphQLForm,
} from '../../Form';
import {SubjectIndexDocument} from '../../Subjects/SubjectIndex.query';
import {SchoolIndexDocument} from '../../Schools/SchoolIndex.query';
import {useI18n} from '../../i18n';
import {ProgramIndexDocument} from '../ProgramIndex.query';
import {ProgramDocument} from '../Program.query';
import {ProgramUpdateDocument} from './ProgramUpdate.mutation';
import {ProgramDeleteDocument} from './ProgramDelete.mutation';
import {CreateProgramDocument} from './ProgramCreate.mutation';
import {ProgramLength} from './ProgramLengthFields';
import style from './ProgramForm.module.css';
import type {Program} from '../types';
import type {CreateProgramMutation} from './ProgramCreate.mutation';
import {StudentProgramRecommendationsDocument} from '../../Students/Student/components/UserProgramRecommendations/StudentProgramRecommendations.query';
import {UserOverviewDocument} from '../../Students/Student/UserOverview.query';
import {useSelectedUser} from '../../Students/SelectedStudentProvider';

interface Props {
	program?: Program | null;
	onSubmit?: (data?: CreateProgramMutation) => void;
	onCancel?: VoidFunction;
}

export function ProgramForm({program, onSubmit, onCancel}: Props) {
	const {t} = useI18n();
	const router = useRouter();
	const applicationRequirement = program?.applicationRequirements?.[0];
	const isCreate = !program?.id;
	const {user} = useSelectedUser();

	const mutation = program?.id ? ProgramUpdateDocument : CreateProgramDocument;
	const [deleteProgram] = useMutation(ProgramDeleteDocument, {
		onSuccess: () => router.push(Paths.programs.programs.index.url()),
		invalidateQueries: [{document: ProgramIndexDocument}],
	});

	const schoolSearchQuery = useImperativeQuery(SchoolIndexDocument);
	const subjectSearchQuery = useImperativeQuery(SubjectIndexDocument);

	return (
		<>
			<Heading type="h3" spacing="lg">
				{isCreate
					? t('program.program_form.title_add')
					: t('program.program_form.title_edit')}
			</Heading>
			<GraphQLForm
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				mutationDocument={mutation as any}
				defaultValues={{
					id: program?.id,
					...(isCreate
						? {applicationRequirementId: applicationRequirement?.id}
						: {}),
					program: {
						name: program?.name,
						subjectId: program?.subject?.id,
						schoolId: program?.school.id,
						levelOfStudy: program?.levelOfStudy,
						programWebsite: program?.programWebsite,
						coop: program?.coop ?? false,
						pgwpEligible: program?.pgwpEligible ?? false,
						minLength: program?.minLength,
						maxLength: program?.maxLength,
					},
					...(isCreate
						? {
								applicationRequirement: {
									requiredGrades: applicationRequirement?.requiredGrades,
									tuition: applicationRequirement?.tuition,
									applicationFee: applicationRequirement?.applicationFee,
									ieltsRequiredScore:
										applicationRequirement?.ieltsRequiredScore,
									toeflRequiredScore:
										applicationRequirement?.toeflRequiredScore,
									pteRequiredScore: applicationRequirement?.pteRequiredScore,
									duolingoRequiredScore:
										applicationRequirement?.duolingoRequiredScore,
									requiredApplicationDocuments:
										applicationRequirement?.requiredApplicationDocuments ?? [],
								},
							}
						: {}),
				}}
				invalidateQueries={[
					{document: ProgramIndexDocument},
					{document: ProgramDocument, variables: {id: program?.id}},
					{
						document: StudentProgramRecommendationsDocument,
						variables: {
							id: user?.studentProfile?.id,
						},
					},
					{
						document: UserOverviewDocument,
						variables: {id: user?.id},
					},
				]}
				onSuccess={(data: CreateProgramMutation) => onSubmit?.(data)}
			>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing="md"
				>
					<Stack direction="column" alignItems="stretch">
						<FormField
							name="program.name"
							label={t('program.program_form.name')}
							rules={{
								required: t('validation.mixed.required'),
							}}
						/>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="stretch"
							spacing="sm"
							className={style.RowFields}
						>
							<FormAsyncSelect
								name="program.schoolId"
								label={t('program.program_form.school')}
								rules={{
									required: t('validation.mixed.required'),
								}}
								defaultInputValue={program?.school.name}
								loadOptions={(search: string) => {
									return schoolSearchQuery({variables: {search}}).then(
										({schools}) => {
											return schools.map((school) => ({
												label: school.city
													? `${school.name} - ${school.city}`
													: school.name,
												value: school.id,
											}));
										},
									);
								}}
							/>
							<FormAsyncSelect
								name="program.subjectId"
								label={t('program.program_form.subject')}
								rules={{
									required: t('validation.mixed.required'),
								}}
								defaultInputValue={program?.subject?.name}
								loadOptions={(search: string) => {
									return subjectSearchQuery({variables: {search}}).then(
										({subjects}) => {
											return subjects.map((subject) => ({
												label: subject.name,
												value: subject.id,
											}));
										},
									);
								}}
							/>
						</Stack>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="stretch"
							spacing="sm"
							className={style.RowFields}
						>
							<FormSelect
								name="program.levelOfStudy"
								label={t('program.program_form.level_of_study')}
								rules={{
									required: t('validation.mixed.required'),
								}}
								options={(Object.keys(STUDY_LEVELS) as LevelOfStudy[]).map(
									(key) => ({
										value: key,
										label: t(STUDY_LEVELS[key]),
									}),
								)}
							/>
							{isCreate ? (
								<FormField
									name="applicationRequirement.requiredGrades"
									label={t('program.program_form.min_grades')}
									placeholder={t('program.program_form.min_grades_placeholder')}
									type="number"
									rules={{
										min: {
											value: 0,
											message: t('validation.number.more', {num: 0}),
										},
										max: {
											value: 100,
											message: t('validation.number.less', {num: 100}),
										},
									}}
								/>
							) : null}
						</Stack>
						<FormField
							name="program.coop"
							label={t('program.program_form.coop')}
							type="checkbox"
						/>
						<FormField
							name="program.pgwpEligible"
							label={t('program.program_form.pgwp_eligible')}
							type="checkbox"
						/>
						{isCreate ? (
							<Stack
								direction="row"
								justifyContent="flex-start"
								alignItems="stretch"
								spacing="sm"
								className={style.RowFields}
							>
								<FormCurrencyField
									name="applicationRequirement.tuition"
									label={t('program.program_form.tuition')}
								/>
								<FormCurrencyField
									name="applicationRequirement.applicationFee"
									label={t('program.program_form.application_fee')}
								/>
							</Stack>
						) : null}
						<ProgramLength program={program} requireMaxLength />
						<FormField
							name="program.programWebsite"
							label={t('program.program_form.program_website')}
							rules={{
								pattern: {
									value: URL_REGEX,
									message: 'Must be a valid URL',
								},
							}}
						/>
					</Stack>
					{isCreate ? (
						<>
							<Stack direction="column" alignItems="stretch">
								<Heading type="h5" spacing="md">
									{t('program.program_form.english_test_requirements.title')}
								</Heading>
								<FormField
									name="applicationRequirement.ieltsRequiredScore"
									label={t(
										'program.program_form.english_test_requirements.ielts',
									)}
									type="float"
									max={9}
									min={1}
									step={0.5}
								/>
								<FormField
									name="applicationRequirement.toeflRequiredScore"
									label={t(
										'program.program_form.english_test_requirements.toefl',
									)}
									type="number"
									max={120}
									min={0}
								/>
								<FormField
									name="applicationRequirement.pteRequiredScore"
									label={t(
										'program.program_form.english_test_requirements.pte',
									)}
									type="float"
									max={90}
									min={10}
									step={0.5}
								/>
								<FormField
									name="applicationRequirement.duolingoRequiredScore"
									label={t(
										'program.program_form.english_test_requirements.duolingo',
									)}
									type="number"
									max={160}
									min={10}
								/>
							</Stack>
							<DocumentTypeSelector name="applicationRequirement.requiredApplicationDocuments" />
						</>
					) : null}
				</Stack>
				<Stack justifyContent="space-between" className={style.Submit}>
					{!isCreate ? (
						<Button
							thin
							color="destructive"
							onClick={() => {
								deleteProgram({id: program?.id});
								onSubmit?.();
							}}
						>
							{t('delete')}
						</Button>
					) : null}
					<Stack spacing="xs">
						<Button thin color="secondary" onClick={onCancel}>
							{t('cancel')}
						</Button>
						<FormButton>{t('submit')}</FormButton>
					</Stack>
				</Stack>
			</GraphQLForm>
		</>
	);
}
