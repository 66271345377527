export type StudentDeliveryMethod = 'IN_PERSON' | 'HYBRID' | 'ONLINE' | 'OTHER';

export const STUDENT_DELIVERY_METHOD: {[key in StudentDeliveryMethod]: string} =
	{
		IN_PERSON: 'delivery_method.in_person',
		HYBRID: 'delivery_method.hybrid',
		ONLINE: 'delivery_method.online',
		OTHER: 'delivery_method.other',
	};

export type StudentMotivation =
	| 'CAREER_CHANGE'
	| 'START_CAREER'
	| 'CAREER_GROWTH'
	| 'INCREASE_SALARY'
	| 'STABILITY';

export const STUDENT_MOTIVATION: {[key in StudentMotivation]: string} = {
	CAREER_CHANGE: 'student_motivation.career_change',
	START_CAREER: 'student_motivation.start_career',
	CAREER_GROWTH: 'student_motivation.career_growth',
	INCREASE_SALARY: 'student_motivation.increase_salary',
	STABILITY: 'student_motivation.stability',
};

export type CitizenshipTypes =
	| 'CANADIAN_CITIZEN_BORN_OUTSIDE_CANADA'
	| 'CANADIAN_CITIZEN_BORN_IN_CANADA'
	| 'PERMANENT_RESIDENT'
	| 'REFUGEE'
	| 'VISA'
	| 'PROTECTED_PERSON'
	| 'OTHER';

export const CITIZENSHIP: {[key in CitizenshipTypes]: string} = {
	CANADIAN_CITIZEN_BORN_OUTSIDE_CANADA:
		'citizenship.canadian_citizen_born_outside_canada',
	CANADIAN_CITIZEN_BORN_IN_CANADA:
		'citizenship.canadian_citizen_born_in_canada',
	PERMANENT_RESIDENT: 'citizenship.permanent_resident',
	REFUGEE: 'citizenship.refugee',
	VISA: 'citizenship.visa',
	PROTECTED_PERSON: 'citizenship.protected_person',
	OTHER: 'citizenship.other',
};

export type RecentEducation =
	| 'GRADE_SCHOOL'
	| 'HIGH_SCHOOL'
	| 'BACHELORS_DEGREE'
	| 'MASTERS_DEGREE'
	| 'DIPLOMA_OR_CERTIFICATE';

export const RECENT_EDUCATION: {[key in RecentEducation]: string} = {
	GRADE_SCHOOL: 'recent_education.grade_school',
	HIGH_SCHOOL: 'recent_education.high_school',
	BACHELORS_DEGREE: 'recent_education.bachelors_degree',
	MASTERS_DEGREE: 'recent_education.masters_degree',
	DIPLOMA_OR_CERTIFICATE: 'recent_education.diploma_or_certificate',
};

export type PreferredClassTime = 'MORNING' | 'AFTERNOON' | 'EVENING' | 'OTHER';

export const PREFERRED_CLASS_TIME: {[key in PreferredClassTime]: string} = {
	MORNING: 'preferred_class_time.morning',
	AFTERNOON: 'preferred_class_time.afternoon',
	EVENING: 'preferred_class_time.evening',
	OTHER: 'preferred_class_time.other',
};

export type FundingPlanTypes =
	| 'SAVINGS'
	| 'LOANS_AND_GRANTS'
	| 'HYBRID'
	| 'DONT_KNOW';

export const FUNDING_PLAN: {[key in FundingPlanTypes]: string} = {
	SAVINGS: 'funding_plan.savings',
	LOANS_AND_GRANTS: 'funding_plan.loans_and_grants',
	HYBRID: 'funding_plan.hybrid',
	DONT_KNOW: 'funding_plan.dont_know',
};

export type FundingHistory =
	| 'PROVINCIAL_FUNDING'
	| 'EMPLOYMENT_INSURANCE'
	| 'DISABILITY_FUNDING'
	| 'NONE';

export const FUNDING_HISTORY: {[key in FundingHistory]: string} = {
	PROVINCIAL_FUNDING: 'funding_history.provincial_funding',
	EMPLOYMENT_INSURANCE: 'funding_history.employment_insurance',
	DISABILITY_FUNDING: 'funding_history.disability_funding',
	NONE: 'funding_history.none',
};

export type TrainingType = 'PLACEMENT_SPEED' | 'PLACEMENT_OUTCOME';

export const TRAINING_TYPE: {[key in TrainingType]: string} = {
	PLACEMENT_SPEED: 'training_type.placement_speed',
	PLACEMENT_OUTCOME: 'training_type.placement_outcome',
};

export type PreferredOutreachMethod = 'TEXT' | 'WHATSAPP';

export const PREFERRED_OUTREACH_METHOD: {
	[key in PreferredOutreachMethod]: string;
} = {
	TEXT: 'preferred_outreach_method.text',
	WHATSAPP: 'preferred_outreach_method.whatsapp',
};
