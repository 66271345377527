/* eslint-disable @typescript-eslint/naming-convention */

export const OFFSHORE_ARCHIVAL_OPTIONS = {
	inactive_first_outreach: 'Inactive - First Outreach',
	inactive_unresponsive: 'Inactive - Unresponsive',
	inactive_document_pending: 'Inactive - Waiting for Doc',
	inactive_intake: 'Inactive - Intake',
	disqualified_general_misalignment: 'DQ - Other Support',
	disqualified_age: 'DQ - Age',
	disqualified_study_gap: 'DQ - Study Gap',
	disqualified_requires_financing: 'DQ - Requires Financing',
	disqualified_tuition_budget: 'DQ - Tuition Budget',
	disqualified_living_expenses_budget: 'DQ - Living Expenses Budget',
	disqualified_grades: 'DQ - Grades',
	disqualified_passport: 'DQ - Passport',
	disqualified_visa_refusal: 'DQ - Study Visa Refusal',
	disqualified_app_fee_refusal: 'DQ - App Fee Refusal',
	disqualified_document_refusal: 'DQ - Doc Refusal',
};

export const DOMESTIC_ARCHIVAL_OPTIONS = {
	inactive_first_outreach: 'Inactive - First Outreach',
	inactive_unresponsive: 'Inactive - Unresponsive',
	inactive_document_pending: 'Inactive - Waiting for Doc',
	inactive_intake: 'Inactive - Intake',
	disqualified_nod: 'DQ - Refugee NOD',
	disqualified_no_program: 'DQ - No Program',
	disqualified_location: 'DQ - Location',
	disqualified_ineligible_for_financing: 'DQ - Ineligible for Financing',
	disqualified_visa_status: 'DQ - Visa Status',
	disqualified_tuition_budget: 'DQ - Tuition Budget',
	disqualified_looking_for_job: 'DQ - Looking for Job',
};

export type StudentType =
	| 'OFFSHORE'
	| 'ONSHORE'
	| 'ONSHORE_EXTENDER'
	| 'ONSHORE_SWITCHER'
	| 'DOMESTIC';

export const STUDENT_TYPES: StudentType[] = [
	'ONSHORE',
	'OFFSHORE',
	'ONSHORE_EXTENDER',
	'ONSHORE_SWITCHER',
	'DOMESTIC',
];
