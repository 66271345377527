import {Paragraph} from '@halp/ui';
import style from './FunnelStageCount.module.css';
import classNames from 'classnames';
import {CSSVariants} from '@halp/util';

interface StudentFunnelStageCountProps {
	count: number | undefined;
	type?: 'subtitle2' | 'caption';
	color?: 'purple';
}

export function FunnelStageCount({
	count,
	type = 'subtitle2',
	color,
}: StudentFunnelStageCountProps) {
	const classes = classNames(
		style.FunnelStageCount,
		CSSVariants(style, 'Border', color),
	);

	return (
		<Paragraph type={type} className={classes}>
			{count ?? 0}
		</Paragraph>
	);
}
